import React from "react"
import { Close } from "@styled-icons/material/Close"
import Input from "../../atoms/Input"
import { InitialAvatar, Name } from "../../atoms/Card/styles"
import {
  MedicalHeader,
  CloseButton,
  MedicalData,
  Title,
  Label,
  TextArea,
} from "./styles"

const MedicalForm = ({ info, message, handleClose }) => {
  const medicalData = JSON.parse(message.data)
  return (
    <div>
      <MedicalHeader>
        <CloseButton onClick={handleClose}>
          <Close size="36" />
        </CloseButton>
        <span>Perfil médico / </span>
        <div>
          <InitialAvatar>
            <span>
              {message?.type === "Dependent"
                ? message?.owner[0]
                : info.clientName[0]}
            </span>
          </InitialAvatar>
          <Name>
            {message?.type === "Dependent"
              ? `${message?.owner}(${info.clientName})`
              : info.clientName}
          </Name>
        </div>
      </MedicalHeader>
      <MedicalData>
        <section>
          <Title>Datos Personales</Title>
          <Label>Altura</Label>
          <Input
            disabled
            value={
              medicalData.height
                ? `${medicalData.height}cm`
                : "No proporcionada"
            }
          />
          <Label>Peso</Label>
          <Input
            disabled
            value={
              medicalData.weight
                ? `${medicalData.weight}kg`
                : "No proporcionado"
            }
          />
        </section>
        <section>
          <Title>Alergias</Title>
          <Label>¿Es alérgico a algún medicamento?</Label>
          <Input
            disabled
            value={medicalData.alergicToMedication ? "Sí" : "No"}
          />
          {medicalData.alergicToMedication && (
            <TextArea
              disabled
              readonly
              value={medicalData.alergicMedicationList}
            />
          )}
          <Label>¿Tiene otras alergias?</Label>
          <Input disabled value={medicalData.otherAlergies ? "Sí" : "No"} />
          {medicalData.otherAlergies && (
            <TextArea disabled readonly value={medicalData.otherAlergiesList} />
          )}
        </section>
        <section>
          <Title>Familiares</Title>
          <Label>¿Se encuentra su padre con vida?</Label>
          <Input disabled value={medicalData.fatherAlive ? "Sí" : "No"} />
          {medicalData.fatherAlive && (
            <>
              <Label>¿Padeció o padece su padre alguna enfermedad?</Label>
              <Input
                disabled
                value={medicalData.fatherHasDiseases ? "Sí" : "No"}
              />
              {medicalData.fatherHasDiseases && (
                <>
                  <Label>Lista de padecimientos</Label>
                  <TextArea
                    disabled
                    readonly
                    value={medicalData.fatherDiseases}
                  />
                </>
              )}
            </>
          )}
          <Label>¿Se encuentra su madre con vida?</Label>
          <Input disabled value={medicalData.motherAlive ? "Sí" : "No"} />
          {medicalData.motherAlive && (
            <>
              <Label>¿Padeció o padece su madre alguna enfermedad?</Label>
              <Input
                disabled
                value={medicalData.motherHasDiseases ? "Sí" : "No"}
              />
              {medicalData.motherHasDiseases && (
                <>
                  <Label>Lista de padecimientos</Label>
                  <TextArea
                    disabled
                    readonly
                    value={medicalData.motherDiseases}
                  />
                </>
              )}
            </>
          )}
          <Label>¿Tiene hermanos y/o hermanas?</Label>
          <Input
            disabled
            value={medicalData.hasBothersOrSisters ? "Sí" : "No"}
          />
          {medicalData.hasBothersOrSisters && (
            <>
              <Label>¿Cuantos hermanos y/o hermanas tiene?</Label>
              <Input disabled value={medicalData.numberOfBothersAndSister} />
              <Label>
                ¿Padecieron o padecen sus hermanos/hermanas de alguna
                enfermedad?
              </Label>
              <Input
                disabled
                value={medicalData.brothersOrSistersHaveDiseases ? "Sí" : "No"}
              />
              {medicalData.brothersOrSistersHaveDiseases && (
                <>
                  <Label>Lista de padecimientos</Label>
                  <TextArea
                    disabled
                    readonly
                    value={medicalData.brothersOrSistersDiseases}
                  />
                </>
              )}
            </>
          )}
        </section>
        <section>
          <Title>Medicación</Title>
          <Label>¿Toma con regularidad algún medicamento?</Label>
          <Input disabled value={medicalData.regularMedication ? "Sí" : "No"} />
          {medicalData.regularMedication && (
            <TextArea
              disabled
              readonly
              value={medicalData.regularMedicationList}
            />
          )}
        </section>
        <section>
          <Title>Estilo de vida</Title>
          <Label>¿Usted fuma?</Label>
          <Input disabled value={medicalData.doYouSmoke ? "Sí" : "No"} />
          {medicalData.doYouSmoke && (
            <>
              <Label>¿Cuántos cigarros fuma al día?</Label>
              <Input disabled value={medicalData.cigarettesPerDay} />
              <Label>¿Cuántos años tiene de estar fumando?</Label>
              <Input disabled value={medicalData.yearsSmoking} />
            </>
          )}
          <Label>¿Consume usted cocaína, marihuana u otra sustancia?</Label>
          <Input disabled value={medicalData.otherSubstances ? "Sí" : "No"} />
          {medicalData.otherSubstances && (
            <>
              <Label>¿Cuáles drogas o sustancias consume?</Label>
              <TextArea
                disabled
                readonly
                value={medicalData.otherSubstancesList}
              />
            </>
          )}
          <Label>¿Actualmente toma bebidas alcohólicas?</Label>
          <Input disabled value={medicalData.doYouDrink ? "Sí" : "No"} />
          {medicalData.doYouDrink && (
            <>
              <Label>¿Con qué frecuencia toma bebidas alcohólicas?</Label>
              <Input disabled value={medicalData.drinkFrequency} />
            </>
          )}
        </section>
        <section>
          <Title>Actividad física</Title>
          <Label>¿Hace ejercicio regularmente?</Label>
          <Input disabled value={medicalData.doYouExercise ? "Sí" : "No"} />
          {medicalData.doYouExercise && (
            <>
              <Label>¿Con qué frecuencia hace ejercicio?</Label>
              <Input disabled value={medicalData.exerciseFrequency} />
            </>
          )}
        </section>
        <section>
          <Title>Cirugías</Title>
          <Label>¿Se ha realizado alguna cirugía en el pasado?</Label>
          <Input disabled value={medicalData.anySurgery ? "Sí" : "No"} />
          {medicalData.anySurgery && (
            <>
              <Label>¿Qué procedimientos se ha practicado?</Label>
              <Input disabled value={medicalData.surgeryList} />
            </>
          )}
        </section>
        <section>
          <Title>Enfermedades crónicas</Title>
          <Label>¿Tiene algún padecimiento crónico?</Label>
          <Input disabled value={medicalData.anyChronicIllness ? "Sí" : "No"} />
          {medicalData.anyChronicIllness && (
            <>
              <Label>¿De qué padecimientos sufre?</Label>
              <Input disabled value={medicalData.chronicIllnessList} />
            </>
          )}
        </section>
      </MedicalData>
    </div>
  )
}

export default MedicalForm
