/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { useTranslation } from "react-i18next"
import { useSpring, animated } from "react-spring"

const getMessageText = (type) => {
  const messages = {
    loaderPrescription: "generating_file",
    loaderClinical: "generating_file",
    loaderMedicalImages: "generating_file",
  }
  return messages[type]
}
const MessageLoader = ({ type, loading }) => {
  const { t } = useTranslation()
  const fadeOutProps = useSpring({ opacity: loading ? 1 : 0 })
  return (
    <Wrapper>
      <animated.div style={fadeOutProps}>
        <Loader />
      </animated.div>
      <p>{t(getMessageText(type))}</p>
    </Wrapper>
  )
}

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: ${loaderAnimation} 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
`

const Wrapper = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > svg {
    color: white;
  }
  & > p {
    ${(props) => props.theme.font_size.xxxsmall};
    color: white;
    margin-bottom: 3px;
  }
`
MessageLoader.defaultProps = {
  loading: true,
}

MessageLoader.propTypes = {
  type: PropTypes.string.isRequired,
  loading: PropTypes.bool,
}

export default MessageLoader
