import React, { useEffect, useState } from "react"
import get from "lodash.get"
import truncate from "lodash.truncate"
import moment from "moment"
import { useTranslation } from "react-i18next"
import Select from "react-select"

// Components
import {
  Flex,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react"
import { RemoveRedEye } from "@styled-icons/material/RemoveRedEye"
import { TextArea } from "../../../../atoms/Forms"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
    marginLeft: 10,
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const Instructions = ({
  firebase,
  contact,
  currentDependent,
  dependents,
  dependentsWithOwner,
  setCurrentDependent,
}) => {
  const [instructions, setInstructions] = useState([])
  const [currentInstruction, setCurrentInstruction] = useState(null)

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()
  const { t } = useTranslation()

  useEffect(() => {
    const getInstructions = async () => {
      const data = await firebase.getInstructions(contact?.email)
      if (data && data.docs) {
        const tipsData = data.docs.map((d) => d.data())
        setInstructions(tipsData)
      }
    }
    if (firebase && contact?.email) {
      getInstructions()
    }
  }, [firebase, contact?.email])

  // return (
  //   <Flex
  //     flexDirection="column"
  //     justifyContent={
  //       instructions && instructions.length === 0 ? "center" : "flex-start"
  //     }
  //     alignItems={
  //       instructions && instructions.length === 0 ? "center" : "flex-start"
  //     }
  //     height="calc(100vh - 240px)"
  //     width="100%"
  //     overflow="auto"
  //   >
  //     <EmptyMessage>{t("web_client.empty_instructions")}</EmptyMessage>
  return (
    <>
      {instructions.length === 0 ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 240px)"
          width="100%"
        >
          <EmptyMessage>{t("web_client.empty_instructions")}</EmptyMessage>
        </Flex>
      ) : (
        <Flex
          key="main-notes"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="calc(100vh - 200px)"
          width="100%"
          position="relative"
          overflow="hidden"
        >
          <Flex
            key="notes-container"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            height="calc(100vh - 260px)"
            width="100%"
            overflowY="auto"
          >
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              borderBottom="1px solid #edf2f7;"
              width="100%"
            >
              {dependents.length > 0 && (
                <Flex justifyContent="center" alignItems="center" my="5px">
                  <Box as="span" ml="10px">
                    Filtrar:
                  </Box>
                  <Select
                    styles={customStyles}
                    defaultValue={currentDependent}
                    value={dependentsWithOwner.find(
                      (option) =>
                        option.value === get(currentDependent, "value")
                    )}
                    options={dependentsWithOwner}
                    onChange={(option) => setCurrentDependent(option || null)}
                    placeholder="Seleccione para filtrar"
                    isClearable
                    width="240px"
                  />
                </Flex>
              )}
            </Flex>
            {instructions
              .filter((n) => {
                if (currentDependent && !currentDependent.isParent) {
                  return n.dependentContact === currentDependent.value
                }
                if (currentDependent && currentDependent.isParent) {
                  return !n.dependentContact
                }
                return true
              })
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((c) => (
                <DataRow mb="15px" key={c.id}>
                  <CustomText>
                    {c.dependentContact ? c.dependentName : contact.displayName}
                  </CustomText>
                  <CustomText>
                    {moment(c.createdAt).format("MMM DD, YYYY").toUpperCase()}
                  </CustomText>
                  <CustomText>
                    {moment(c.createdAt).format("HH:mm:ss").toUpperCase()}
                  </CustomText>
                  <CustomText flex={2}>
                    {truncate(c.tip, { length: 38 })}
                  </CustomText>
                  <Button
                    h="35px"
                    width="48px"
                    borderRadius="8px"
                    padding="10px, 12px, 10px, 12px"
                    background="#EDF2F7"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="0px"
                    opacity="0.8"
                    _hover={{
                      background: "#EDF2F7",
                      transform: "scale(1.1)",
                      opacity: 1,
                    }}
                    onClick={() => {
                      setCurrentInstruction(c.tip)
                      onModalOpen()
                    }}
                  >
                    <RemoveRedEye size="22px" />
                  </Button>
                </DataRow>
              ))}
          </Flex>
        </Flex>
      )}
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isModalOpen}
        onClose={onModalClose}
      >
        <ModalOverlay />
        <ModalContent minW="690px">
          <ModalHeader width="690px" backgroundColor="white" />
          <ModalCloseButton />
          <ModalBody
            backgroundColor="white"
            pb={1}
            pt={1}
            pl={0}
            pr={0}
            width="690px"
          >
            <TextArea
              key="currentInstruction"
              mt="10px"
              mb="0px"
              height="25vh"
              value={currentInstruction || ""}
              id="currentInstruction"
              name="currentInstruction"
              disabled
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Instructions
