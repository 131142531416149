import styled from "styled-components"

import { GridContainer } from "../Global"

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.gray30};
`

export const EditableWrapper = styled.div`
  margin-top: 40px;
`

export const EditableSection = styled.div`
  width: auto;
  min-height: 200px;
  border-radius: 4px;
  border: ${(props) => `solid 1px ${props.theme.color.iceBlue}`};
  background-color: ${(props) => props.theme.color.background.white.regular};
  margin: 0 27px 32px 30px;
  padding: 20px 20px 35px;
  color: ${(props) => props.theme.color.black.medium};
  & > p {
    color: ${(props) => props.theme.color.black.normal};
  }
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const EditableHeader = styled.div`
  button {
    color: ${(props) => props.theme.color.black.medium};
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:hover {
      transform: scale(1.1);
      & > span {
        color: ${(props) => props.theme.color.primary};
      }
    }
    & > span {
      margin-right: 5px;
      b {
        color: black;
      }
    }
  }
`

export const Number = styled.div`
  background-color: black;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
`

export const MedicalHeader = styled.div`
  height: 88px;
  display: flex;
  align-items: center;
  & > span {
    ${(props) => props.theme.font_size.regular};
    min-width: 270px;
  }
  & > div {
    margin-left: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const CloseButton = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 5px;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  margin-right: 20px;
  cursor: pointer;
`

export const HeaderTools = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
`
export const InfoCard = styled.section`
  width: 50%;
  height: 160px;
  display: flex;
  flex-direction: column;
  & > span {
    margin: 5px 0;
  }
`

export const MedicalInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29px 40px 60px;
  & > p {
    width: 100%;
  }
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    & > ${InfoCard} {
      height: 160px;
      align-items: center;
      justify-content: center;
      &:nth-child(1),
      &:nth-child(2) {
        min-width: unset;
        margin-left: 10px;
        align-items: flex-start;
        width: auto;
      }
    }
  }
`

export const MedicalPrescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 200px;
  & > section {
    min-height: 300px;
    width: 50%;
    margin: 34px 58px;
    height: 100%;
    & > div {
      margin-bottom: 5px;
    }
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  button: {
    color: black;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
`

export const TextArea = styled.textarea`
  outline: none;
  border: none;
  display: block;
  resize: none;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 10px;
  margin-top: ${({ mt }) => mt || "0px"};
  margin-bottom: ${({ mb }) => mb || "15px"};
  background: ${(props) =>
    props.disabled
      ? props.theme.color.background.gray
      : props.theme.color.background.gray30};
  color: ${(props) =>
    props.disabled ? "#999" : props.theme.color.background.black80};
  font-weight: ${(props) => (props.disabled ? "bold" : "normal")};
  height: ${({ height }) => height || "auto"};
  min-height: ${({ minHeight }) => minHeight || "80px"};
  max-height: ${({ maxHeight }) => maxHeight || "unset"};
  width: 100%;
  border-radius: 4px;
  border: ${(props) =>
    props.showError ? `1px solid ${props.theme.color.alert}` : "none"};
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`
