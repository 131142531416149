/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from "react"
import { useToasts } from "react-toast-notifications"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useHistory } from "react-router-dom"
import { Button, Flex, Spinner, Box } from "@chakra-ui/react"
import { v4 as uuidv4 } from "uuid"
import PropTypes from "prop-types"
import moment from "moment"
import Dropzone from "react-dropzone-uploader"
import get from "lodash.get"

// Icons
import { AttachFile } from "@styled-icons/material/AttachFile"
import { NoteAdd } from "@styled-icons/material/NoteAdd"
import { Edit } from "@styled-icons/material/Edit"
import { Notepad } from "@styled-icons/fluentui-system-filled/Notepad"
import { Stars } from "@styled-icons/bootstrap/Stars"

// Components
import { TextArea } from "../../atoms/Forms"
import { MedicImages } from "../../atoms/Common"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import {
  MessageImage,
  MessageText,
  MessageVideo,
  MessageMedical,
  MessageLocation,
  MessageAudio,
  MessageDocument,
  MessageTip,
  MessageLoader,
} from "../../atoms/MessagesType"
import MessageBubble from "../../atoms/MessageBubble"
import CustomModal from "../../atoms/CustomModal"
import MedicalForm from "../../molecules/MedicalForm"

import SectionTitle from "../../atoms/SectionTitle"
import Input from "../../atoms/Input"

// Utils
import { withFirebase } from "../../../utils/Firebase"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import { postAnswerQuestionUrl } from "../../../utils/App"
import mimeTypes from "../../../utils/mimeTypes"

// Styles
import { Overlay } from "../Chat/styles"

import {
  InfoWrapper,
  InitialAvatar,
  PersonalWrapper,
  Name,
  Service,
  CardWrapper,
} from "../../atoms/Card/styles"

import { Container, Tools } from "../../molecules/ChatFooter/styles"

import { ContactLink } from "../../molecules/ChatHeader/styles"

import {
  NotesContainer,
  NotesList,
  NoteRow,
} from "../../molecules/ChatContainer/styles"

import {
  ContainerFromStyles,
  MessageContainer,
  Search,
  QuestionWrapper,
  HeaderWrapper,
  AnswerButton,
  DonateButton,
  DateBubble,
  QuestionContainer,
  QuestionContent,
  QuestionContentBody,
  Time,
  UserName,
  AnswerContent,
  AnswerContentBody,
  AlignLeft,
  AlignRight,
  FilterQuestions,
  RowContainer,
  NameWrapper,
  ModalWrapper,
  ModalOptions,
  TextAreaWrapper,
  SubmitButton,
  MedicalFormContainer,
  AnswerContentContainer,
} from "./styles"

let interval = null
const twoHour = 3600000 * 2

const Questions = ({ firebase }) => {
  const user = useContext(AuthUserContext)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { addToast } = useToasts()
  const { state } = useLocation()
  const history = useHistory()
  const [questions, setQuestions] = useState([])
  const [formValues, setFormValues] = useState({
    search: "",
    questionTextAnswer: "",
  })
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [questionsFilter, setQuestionsFilter] = useState("submitted")
  const [medicalImages, setMedicalImages] = useState([])
  const [clinicalImages, setClinicalImages] = useState([])
  const [prescriptionsImages, setPrescriptionsImages] = useState([])
  const [myAttachments, setUploaderAttachment] = useState([])
  const [isNotesVisible, setNotesVisible] = useState(false)
  const [medicalNotes, setMedicalNotes] = useState([])
  const [currentContact, setCurrentContact] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [note, setNote] = useState("")
  const [currentNote, setCurrentNote] = useState(null)
  const [currentNoteSession, setCurrentNoteSession] = useState(null)
  const [attachmentsData, setAttachmentData] = useState([])
  const [manualFetchQuestions, setManualFetchQuestions] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [medicalFormVisible, setIsMedicalFormVisible] = useState(false)
  const [medicalForm, setMedicalForm] = useState({})
  const [newQuestionData, setNewQuestionData] = useState(null)
  const [isDonatedQuestion, setIsDonatedQuestion] = useState(false)

  // This attachemnts are added by the user from the app
  const [questionAttachments, setQuestionAttachments] = useState([])

  // Modals
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showChangeQuestionModal, setShowChangeQuestionModal] = useState(false)
  const [hasPendingChanges, setHasPendingChanges] = useState(false)

  const getType = (fileExtension) => {
    switch (fileExtension) {
      case "mp4":
        return "video"
      case "png":
      case "jpg":
      case "jpeg":
        return "image"
      case "pdf":
        return "document"
      default:
        return "other"
    }
  }

  useEffect(() => {
    moment.locale(language)
  }, [language])

  useEffect(() => {
    const lsClinicalImages = localStorage.getItem("clinicalTestArray")
    const lsClinicalImagesParse = JSON.parse(lsClinicalImages) || []
    const lsMedicalImages = localStorage.getItem("medicalImagesArray")
    const lsMedicalImagesParse = JSON.parse(lsMedicalImages) || []
    const lsPrescriptionsImgs = localStorage.getItem("prescriptionsArray")
    const lsPrescriptionsImgsParse = JSON.parse(lsPrescriptionsImgs) || []
    const lsAttachemntsImages = localStorage.getItem("uploaderImages")
    const lsAttachemntsImagesParse = JSON.parse(lsAttachemntsImages) || []
    const questionTextAnswerFromLs = localStorage.getItem("questionTextAnswer")
    const lsQuestionsAttachments = localStorage.getItem("questionAttachments")
    const lsQuestionsAttachmentsParse = JSON.parse(lsQuestionsAttachments) || []

    if (questionTextAnswerFromLs) {
      setFormValues((currentValues) => ({
        ...currentValues,
        questionTextAnswer: questionTextAnswerFromLs,
      }))
    }

    const allData = [
      ...lsClinicalImagesParse,
      ...lsMedicalImagesParse,
      ...lsPrescriptionsImgsParse,
      ...lsAttachemntsImagesParse,
    ]
    if (lsQuestionsAttachmentsParse) {
      setQuestionAttachments(lsQuestionsAttachmentsParse)
    }
    if (allData?.length) {
      setHasPendingChanges(true)
    }
    setAttachmentData(allData?.sort((a, b) => a.createdAt - b.createdAt))
  }, [clinicalImages, medicalImages, prescriptionsImages, myAttachments])

  const getQuestions = async () => {
    try {
      const data = await firebase.getAllQuestions(
        user.isImpersonating ? user.impersonatingEmail : user.email
      )
      if (data && data.docs) {
        const questionsData = data.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }))
        const filteredQuestions = questionsData
          .filter((question) => question.status === questionsFilter)
          .sort((a, b) => b.createdAt - a.createdAt)
        setQuestions(filteredQuestions)
        setManualFetchQuestions(false)
      }
    } catch (err) {
      window.Honeybadger?.notify(`Error on get questions: ${err}`)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  useEffect(() => {
    if (user?.email || user?.impersonatingEmail) {
      getQuestions()
    }
  }, [manualFetchQuestions, questionsFilter])

  useEffect(() => {
    if (user?.email || user?.impersonatingEmail) {
      interval = setInterval(() => {
        getQuestions()
      }, twoHour)
    }
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (questionAttachments.length) {
      localStorage.setItem(
        "questionAttachments",
        JSON.stringify(questionAttachments)
      )
    }
  }, [questionAttachments])

  const setFileData = (lsName, data, docType, stateFunc) => {
    const localStorageItem = localStorage.getItem(lsName)
    const parseImages = JSON.parse(localStorageItem)
    const dataArray = parseImages || []
    const fileExtension = data?.name?.split(".").pop() || "unknown"
    const fileName = `${uuidv4()}.${fileExtension.toLowerCase()}`
    dataArray.push({
      createdAt: Date.now(),
      document: data?.pdfUrl,
      filename: fileName,
      documentTypeForApp: docType,
      type: getType(fileExtension),
    })
    localStorage.setItem(lsName, JSON.stringify(dataArray))
    stateFunc(dataArray)
  }

  const onCardClick = (question, isNewQuestion = false, pendingChanges) => {
    if (question?.id === currentQuestion?.id && !isNewQuestion) {
      return
    }
    if (
      question?.id &&
      currentQuestion?.id &&
      question?.id !== currentQuestion?.id &&
      !isNewQuestion &&
      pendingChanges
    ) {
      setNewQuestionData(question)
      setShowChangeQuestionModal(true)
      return
    }
    localStorage.clear()
    setAttachmentData([])
    setQuestionAttachments([])
    setHasPendingChanges(false)
    setFormValues({ questionTextAnswer: "" })
    setCurrentQuestion(question)
    if (question?.answer?.attachments) {
      const formatMapDataFromFirebase = Object.keys(
        question?.answer?.attachments
      ).map((key) => question?.answer?.attachments[key])
      const formatAsExpectedByComponent =
        formatMapDataFromFirebase &&
        formatMapDataFromFirebase.map((attachment) => {
          const fileExtension = attachment.name.split(".").pop() || "unknown"
          return {
            document: attachment?.url,
            thumb: attachment?.url,
            image: attachment?.url,
            filename: attachment?.name,
            type: getType(fileExtension),
            createdAt: question?.answeredAt,
          }
        })
      setAttachmentData(formatAsExpectedByComponent)
    }
    if (question?.question?.medicalForm?.form) {
      setQuestionAttachments([
        {
          createdAt: question?.submittedAt,
          form: question?.question?.medicalForm?.form,
          profileType: question?.question?.medicalForm?.profileType,
          profileOwner: question?.question?.medicalForm?.profileOwner,
          type: "medical",
        },
      ])
      setMedicalForm({
        data: question?.question?.medicalForm?.form,
        owner: question?.question?.medicalForm?.profileOwner,
        type: question?.question?.medicalForm?.profileType,
      })
    }
    if (question?.question?.attachments) {
      const formatMapDataFromFirebase = Object.keys(
        question?.question?.attachments
      ).map((key) => question?.question?.attachments[key])
      const formatAsExpectedByComponent =
        formatMapDataFromFirebase &&
        formatMapDataFromFirebase.map((attachment) => {
          const fileExtension = attachment?.name?.split(".").pop() || "unknown"
          return {
            document: attachment?.url,
            thumb: attachment?.url,
            image: attachment?.url,
            filename: attachment?.name,
            type: getType(fileExtension),
            createdAt: question?.submittedAt,
          }
        })
      setQuestionAttachments((currentValues) => [
        ...currentValues,
        ...formatAsExpectedByComponent,
      ])
    }
  }

  useEffect(() => {
    if (state?.question?.id && questions && !state?.isFromDashboard) {
      setQuestionsFilter(
        state?.question?.status === "answered" ? "answered" : "submitted"
      )
      setCurrentQuestion(state?.question)
      if (state?.medicalImages) {
        setFileData(
          "medicalImagesArray",
          state?.medicalImages,
          "documents/medicalImages",
          setMedicalImages
        )
      }
      if (state?.clinicalTests) {
        setFileData(
          "clinicalTestArray",
          state?.clinicalTests,
          "documents/clinicalExams",
          setClinicalImages
        )
      }
      if (state?.prescriptions) {
        setFileData(
          "prescriptionsArray",
          state?.prescriptions,
          "documents/prescriptions",
          setPrescriptionsImages
        )
      }
      history.replace({ state: {} })
    }

    if (state?.isFromDashboard) {
      onCardClick(state?.question, false, false)
      history.replace({ state: {} })
    }
  }, [state, questions])

  useEffect(() => {
    const checkContact = async () => {
      const data = await firebase.checkContact(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        currentQuestion.clientEmail
      )
      if (data && !data.empty) {
        const contactData = data.docs[0].data()
        setCurrentContact({ id: data.docs[0].id, ...contactData })
      }
    }
    if (user && currentQuestion) {
      checkContact()
    }
  }, [isNotesVisible])

  useEffect(() => {
    const getNotes = async () => {
      const localNotes = await firebase.getNotes(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        currentContact.id
      )
      if (localNotes) {
        setMedicalNotes(
          localNotes.docs.map((d) => ({
            id: d.id,
            ...d.data(),
          }))
        )
      }
    }
    if ((isNotesVisible && currentContact) || (!isSaving && currentContact)) {
      getNotes()
      setNote("")
    }
  }, [isNotesVisible, currentContact, isSaving])

  const handleAddNote = async () => {
    try {
      setIsSaving(true)
      const currentTime = Date.now()
      const formattedNote = {
        note,
        contact: currentContact.id,
        contactEmail: currentContact.email,
        createdAt: currentTime,
        session: currentQuestion?.id,
      }

      await firebase.AddMedicalNotes({
        doctor: user.email,
        contact: currentContact.id,
        medicalNote: formattedNote,
      })
      setIsSaving(false)
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
      setIsSaving(false)
    }
  }

  const handleEditNote = async () => {
    try {
      setIsSaving(true)
      const currentTime = Date.now()
      const formattedNote = {
        note,
        modifiedAt: currentTime,
      }
      await firebase.updateMedicalNote({
        doctor: user.email,
        contact: currentContact.id,
        medicalNote: formattedNote,
        noteId: currentNote,
      })
      setCurrentNote(null)
      setIsSaving(false)
    } catch {
      setIsSaving(false)
      setCurrentNote(null)
    }
  }

  const clearSelectedNote = () => {
    setNote("")
    setCurrentNote(null)
    setCurrentNoteSession(null)
  }

  const hasCurrentNote = medicalNotes.find(
    (n) => get(n, "session") === currentQuestion?.id
  )

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }))
    if (e.target.value) {
      const filteredQuestions = questions.filter((question) =>
        question.clientDisplayName
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
      setQuestions(filteredQuestions)
    } else {
      setManualFetchQuestions(true)
    }
  }

  const handleQuestionInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      questionTextAnswer: e.target.value,
    }))
    if (e?.target?.value) {
      setHasPendingChanges(true)
    }
  }

  const savePreviousText = (e) => {
    e.persist()
    if (e.target.value) {
      localStorage.setItem("questionTextAnswer", e.target.value)
    }
  }

  const formatQuestionTextResponse = (text) => text.replace(/\n/g, "<br />")

  const submitResponse = async () => {
    try {
      setIsButtonLoading(true)
      const mapAttachments =
        attachmentsData?.length &&
        attachmentsData?.map((attachment) => ({
          name: attachment?.filename,
          type: attachment?.documentTypeForApp,
          url: attachment?.document,
        }))
      const answerPayload = {
        questionId: currentQuestion?.id,
        answer: formatQuestionTextResponse(formValues.questionTextAnswer),
        attachments: mapAttachments,
        donated: isDonatedQuestion,
      }
      const authToken = await firebase.getIdToken()
      const answered = await fetch(postAnswerQuestionUrl, {
        method: "POST",
        body: JSON.stringify(answerPayload),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      if (answered.ok) {
        setCurrentQuestion(null)
        setShowConfirmationModal(false)
        setIsDonatedQuestion(false)
        addToast(t("web_client.question_sucess"), {
          appearance: "success",
        })
        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        addToast(t("unexpected_error"), {
          appearance: "error",
        })
      }
    } catch (err) {
      setShowConfirmationModal(false)
      setIsDonatedQuestion(false)
      window.Honeybadger?.notify(err)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
    setIsButtonLoading(false)
  }

  const getMessageChild = (props) => {
    const { type, ...otherProps } = props
    const types = {
      image: MessageImage,
      text: MessageText,
      system: MessageText,
      video: MessageVideo,
      medical: MessageMedical,
      prescription: MessageMedical,
      clinical: MessageMedical,
      medicalImages: MessageMedical,
      location: MessageLocation,
      audio: MessageAudio,
      document: MessageDocument,
      tip: MessageTip,
      loaderPrescription: MessageLoader,
      loaderClinical: MessageLoader,
      loaderMedicalImages: MessageLoader,
      other: MessageDocument,
    }
    const Component = types[type]
    return <Component currentMessage={otherProps} type={type} />
  }

  const handleChangeStatus = React.useCallback((image, status) => {
    if (status === "headers_received") {
      const fileExtension = image.file.name.split(".").pop() || "unknown"
      const fileName = `${uuidv4()}.${fileExtension.toLowerCase()}`
      const uploadDir = `uploads/${
        currentQuestion?.id
      }/${fileExtension.toLowerCase()}`
      const uploadTask = firebase.uploadMediaFile({
        file: image.file,
        uploadDir,
        fileName,
      })
      uploadTask.on("state_changed", null, null, () => {
        firebase
          .getMediaURL({ uploadDir, fileName })
          .then((url) => {
            const img = {
              filename: image?.meta?.name,
              filepath: image?.meta?.previewUrl,
              image: url,
              document: url,
              type: getType(fileExtension),
              thumb: url,
              documentTypeForApp: mimeTypes[fileExtension],
              createdAt: Date.now(),
            }
            const imagesFromLs = localStorage.getItem("uploaderImages")
            const parseImages = JSON.parse(imagesFromLs)
            const imagesArray = parseImages || []
            imagesArray.push(img)
            localStorage.setItem("uploaderImages", JSON.stringify(imagesArray))
            setUploaderAttachment(imagesArray)
            image.remove()
          })
          .catch(() => {
            addToast("Ocurrió un error en la carga del archivo.", {
              appearance: "error",
            })
          })
      })
    } else if (status === "aborted") {
      addToast(`Se canceló la carga del archivo ${image.meta.name}`, {
        appearance: "error",
      })
    }
  }, [])

  const handleMessageClick = (data) => {
    if (data.type === "medical" && data?.form) {
      setIsMedicalFormVisible(true)
    }
  }

  const createMarkup = () => ({ __html: currentQuestion?.question?.question })

  const createAnswerMarkup = () => ({
    __html: currentQuestion?.answer?.answer,
  })

  const Layout = ({ input, previews, dropzoneProps }) => (
    <div>
      {previews}
      <div {...dropzoneProps}>{input}</div>
    </div>
  )

  return (
    <SectionsDoubleContainer>
      <Toolbar style={{ overflow: "hidden" }}>
        <SectionTitle withPadding>
          {t("web_client.menu_questions")}
        </SectionTitle>
        <RowContainer>
          <FilterQuestions
            active={questionsFilter === "submitted"}
            onClick={() => {
              setQuestionsFilter("submitted")
              setCurrentQuestion(null)
            }}
          >
            {t("web_client.question_without_answer")}
          </FilterQuestions>
          <FilterQuestions
            active={questionsFilter === "answered"}
            onClick={() => {
              setQuestionsFilter("answered")
              setCurrentQuestion(null)
            }}
          >
            {t("web_client.question_answered")}
          </FilterQuestions>
        </RowContainer>
        <Search>
          <Input
            icon="search-person"
            defaultValue={formValues.title}
            onChange={handleInputChange}
            labelName={t("web_client.search")}
            type="text"
            name="search"
          />
        </Search>

        <MessageContainer>
          {questions &&
            questions?.map((question) => (
              <CardWrapper
                key={`${question?.id}${question?.createdAt}`}
                onClick={() => onCardClick(question, false, hasPendingChanges)}
                isActive={question?.id === currentQuestion?.id}
                isReadOnlyChat={false}
                hasBorder
              >
                <InfoWrapper>
                  <InitialAvatar>
                    {question?.clientPhotoURL &&
                    question?.clientPhotoURL !== "local" ? (
                      <img
                        src={question?.clientPhotoURL}
                        alt="avatar"
                        style={{ width: 40 }}
                      />
                    ) : (
                      <span>
                        {question?.clientDisplayName[0]?.toUpperCase()}
                      </span>
                    )}
                  </InitialAvatar>
                  <PersonalWrapper>
                    <Name>{question?.clientDisplayName}</Name>
                    <Service>
                      {moment(question?.submittedAt)
                        .format("MMMM DD, YYYY")
                        .toUpperCase()}
                    </Service>
                  </PersonalWrapper>
                </InfoWrapper>
              </CardWrapper>
            ))}
        </MessageContainer>
      </Toolbar>
      <ContainerFromStyles>
        {currentQuestion && (
          <QuestionWrapper>
            <div style={{ overflow: "auto", height: "100%", minHeight: "80%" }}>
              <HeaderWrapper>
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="calc(100% - 580px)"
                >
                  <Flex ml="15px" flexDirection="row">
                    <Box mr="10px">
                      <InitialAvatar>
                        {currentQuestion?.clientPhotoURL &&
                        currentQuestion?.clientPhotoURL !== "local" ? (
                          <img
                            src={currentQuestion?.clientPhotoURL}
                            alt="avatar"
                            style={{ width: 40 }}
                          />
                        ) : (
                          <span>
                            {currentQuestion?.clientDisplayName[0]?.toUpperCase()}
                          </span>
                        )}
                      </InitialAvatar>
                    </Box>
                    <NameWrapper>
                      <Name>{currentQuestion?.clientDisplayName}</Name>
                      <Link
                        to={{
                          pathname: "/contacts",
                          state: {
                            contact: {
                              email: currentQuestion.clientEmail,
                              displayName: currentQuestion.clientName,
                            },
                            isFromQuestions: true,
                            question: currentQuestion,
                          },
                        }}
                      >
                        <ContactLink>
                          {t("web_client.look_contact")}
                        </ContactLink>
                      </Link>
                    </NameWrapper>
                  </Flex>

                  <Flex flexDirection="row">
                    {currentQuestion?.status === "submitted" && (
                      <AnswerButton
                        isDisabled={!formValues?.questionTextAnswer}
                        onClick={() => {
                          if (formValues?.questionTextAnswer) {
                            setShowConfirmationModal(true)
                          }
                        }}
                      >
                        {t("web_client.question_answer")}
                      </AnswerButton>
                    )}

                    {currentQuestion?.status === "submitted" && (
                      <DonateButton
                        isDisabled={!formValues?.questionTextAnswer}
                        onClick={() => {
                          if (formValues?.questionTextAnswer) {
                            setShowConfirmationModal(true)
                            setIsDonatedQuestion(true)
                          }
                        }}
                      >
                        {t("web_client.question_answer_and_donate")}
                      </DonateButton>
                    )}
                  </Flex>
                </Flex>
              </HeaderWrapper>
              <DateBubble>
                {moment(currentQuestion?.submittedAt)
                  .format("dddd D MMMM")
                  .toUpperCase()}
              </DateBubble>
              <QuestionContainer>
                <AlignRight>
                  <QuestionContent>
                    <Time>
                      {moment(currentQuestion?.submittedAt)
                        .format("hh:mm a")
                        .toUpperCase()}
                    </Time>
                    <UserName>{currentQuestion?.clientDisplayName}</UserName>
                    <InitialAvatar>
                      {currentQuestion?.clientPhotoURL &&
                      currentQuestion?.clientPhotoURL !== "local" ? (
                        <img
                          src={currentQuestion?.clientPhotoURL}
                          alt="avatar"
                          style={{ width: 40 }}
                        />
                      ) : (
                        <span>
                          {currentQuestion?.clientDisplayName[0]?.toUpperCase()}
                        </span>
                      )}
                    </InitialAvatar>
                  </QuestionContent>
                  <QuestionContentBody>
                    <div dangerouslySetInnerHTML={createMarkup()} />
                    <div style={{ marginTop: 30 }}>
                      {questionAttachments &&
                        questionAttachments?.map((attachment, index) => (
                          <MessageBubble
                            key={`attachment-${index}`}
                            isPatient={false}
                            type={attachment?.type}
                            content={attachment}
                            handleClick={handleMessageClick}
                          >
                            {getMessageChild(attachment)}
                          </MessageBubble>
                        ))}
                    </div>
                  </QuestionContentBody>
                  {isNotesVisible && (
                    <NotesContainer topPosition="10%">
                      <NotesList>
                        {medicalNotes &&
                          medicalNotes.length > 0 &&
                          medicalNotes
                            .sort((a, b) => b.createdAt - a.createdAt)
                            .map((n) => (
                              <NoteRow
                                isCurrentNoteSession={
                                  get(n, "session") === currentQuestion?.id
                                }
                                isActive={n.id === currentNote}
                                key={n.id}
                                onClick={() => {
                                  if (currentNote) {
                                    if (n.id === currentNote) {
                                      setNote("")
                                      setCurrentNoteSession(null)
                                      setCurrentNote(null)
                                    } else {
                                      setNote(n.note)
                                      setCurrentNoteSession(n.session)
                                      setCurrentNote(n.id)
                                    }
                                  } else {
                                    setNote(n.note)
                                    setCurrentNoteSession(n.session)
                                    setCurrentNote(n.id)
                                  }
                                }}
                              >
                                <span>
                                  {moment(n.createdAt)
                                    .format("MMM DD, YYYY HH:mm:ss")
                                    .toUpperCase()}
                                </span>
                                <span>
                                  {n.dependentContact
                                    ? n.dependentName
                                    : t("web_client.attendant")}
                                </span>
                              </NoteRow>
                            ))}
                      </NotesList>
                      <TextArea
                        key="new-note"
                        mt="10px"
                        mb="0px"
                        height="30vh"
                        value={note}
                        onChange={(e) => {
                          const { value } = e.target
                          setNote(value)
                        }}
                        placeholder={
                          hasCurrentNote
                            ? t("web_client.add_update_to_notes")
                            : `${t("web_client.add_notes")} ${t(
                                "web_client.patient"
                              )}`
                        }
                        id="note"
                        name="note"
                        disabled={
                          (!hasCurrentNote && currentNote) ||
                          (Boolean(hasCurrentNote) &&
                            currentNoteSession !== currentQuestion?.id) ||
                          (Boolean(hasCurrentNote) &&
                            Boolean(currentNoteSession) === false)
                        }
                      />
                      <Flex
                        height="8vh"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Button
                          width="100px"
                          borderRadius="5px"
                          p="5px"
                          border="1px solid rgba(226, 232, 240, 1)"
                          mx="5px"
                          onClick={
                            currentNote
                              ? clearSelectedNote
                              : () => setNotesVisible(false)
                          }
                          minH="40px"
                        >
                          {currentNote &&
                          currentNoteSession !== currentQuestion?.id
                            ? t("cancel")
                            : "Cerrar"}
                        </Button>
                        <Button
                          width="100px"
                          borderRadius="5px"
                          p="5px"
                          border="1px solid rgba(226, 232, 240, 1)"
                          mx="5px"
                          onClick={() =>
                            currentNoteSession === currentQuestion?.id
                              ? handleEditNote()
                              : handleAddNote()
                          }
                          minH="40px"
                          disabled={
                            (hasCurrentNote &&
                              currentNoteSession !== currentQuestion?.id) ||
                            (!hasCurrentNote && currentNote)
                          }
                        >
                          {isSaving ? (
                            <Spinner size="sm" />
                          ) : currentNoteSession === currentQuestion?.id ? (
                            t("edit")
                          ) : (
                            t("save")
                          )}
                        </Button>
                      </Flex>
                    </NotesContainer>
                  )}
                </AlignRight>
                <AlignLeft>
                  {currentQuestion?.answer?.answer && (
                    <AnswerContentContainer>
                      <AnswerContent>
                        <InitialAvatar>
                          {currentQuestion?.clientPhotoURL &&
                          currentQuestion?.clientPhotoURL !== "local" ? (
                            <img
                              src={currentQuestion?.clientPhotoURL}
                              alt="avatar"
                              style={{ width: 40 }}
                            />
                          ) : (
                            <span>
                              {currentQuestion?.clientDisplayName[0]?.toUpperCase()}
                            </span>
                          )}
                        </InitialAvatar>
                        <UserName>{`${currentQuestion?.providerTitle}. ${currentQuestion?.providerDisplayName}`}</UserName>
                        <Time>
                          {moment(currentQuestion?.answeredAt)
                            .format("hh:mm a")
                            .toUpperCase()}
                        </Time>
                      </AnswerContent>
                      <AnswerContentBody>
                        <div dangerouslySetInnerHTML={createAnswerMarkup()} />
                      </AnswerContentBody>
                    </AnswerContentContainer>
                  )}
                  {attachmentsData &&
                    attachmentsData?.map((attachment, index) => (
                      <MessageBubble
                        key={`attachment-${index}`}
                        isPatient
                        type={attachment?.type}
                        content={attachment}
                      >
                        {getMessageChild(attachment)}
                      </MessageBubble>
                    ))}
                </AlignLeft>
              </QuestionContainer>
            </div>
            {currentQuestion?.status !== "answered" && (
              <Container maxHeight="200px" heightSize="200px">
                <Tools isEnabled>
                  <div
                    style={{ userSelect: "none" }}
                    aria-hidden="true"
                    onClick={() => setNotesVisible(true)}
                  >
                    <div>
                      <Notepad size="24" />
                      <span>{t("add_medical_note")}</span>
                    </div>
                  </div>
                  <div style={{ userSelect: "none" }} aria-hidden="true">
                    <Link
                      to={{
                        pathname: "/contacts",
                        state: {
                          contact: {
                            email: currentQuestion.clientEmail,
                            displayName: currentQuestion.clientName,
                          },
                          isFromQuestions: true,
                          question: currentQuestion,
                          subpath: "medical_follow",
                        },
                      }}
                    >
                      <div>
                        <Stars size="24" color="#ECC94B" />
                        <span>{t("add_medical_follow")}</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/medical-images",
                        state: {
                          session: currentQuestion?.id,
                          user: {
                            email: currentQuestion?.providerEmail,
                            name: currentQuestion?.providerDisplayName,
                          },
                          info: {
                            clientEmail: currentQuestion?.clientEmail,
                            providerName: currentQuestion?.providerDisplayName,
                            clientName: currentQuestion?.clientDisplayName,
                          },
                          isFromQuestions: true,
                          question: currentQuestion,
                        },
                      }}
                    >
                      <div>
                        <MedicImages />
                        <span>{t("web_client.medical_images")}</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/clinical",
                        state: {
                          session: currentQuestion?.id,
                          user: {
                            email: currentQuestion?.providerEmail,
                            name: currentQuestion?.providerDisplayName,
                          },
                          info: {
                            clientEmail: currentQuestion?.clientEmail,
                            providerName: currentQuestion?.providerDisplayName,
                            clientName: currentQuestion?.clientDisplayName,
                          },
                          isFromQuestions: true,
                          question: currentQuestion,
                        },
                      }}
                    >
                      <div>
                        <Edit size="24" />
                        <span>{t("web_client.clinical_studies")}</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={{
                        pathname: "/prescription",
                        state: {
                          session: currentQuestion?.id,
                          user: {
                            email: currentQuestion?.providerEmail,
                            name: currentQuestion?.providerDisplayName,
                          },
                          info: {
                            clientEmail: currentQuestion?.clientEmail,
                            providerName: currentQuestion?.providerDisplayName,
                            clientName: currentQuestion?.clientDisplayName,
                          },
                          isFromQuestions: true,
                          question: currentQuestion,
                        },
                      }}
                    >
                      <div>
                        <NoteAdd size="24" />
                        <span>{t("web_client.prescription")}</span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Dropzone
                      disabled={false}
                      getUploadParams={() => ({
                        url: "https://httpbin.org/post",
                      })}
                      onChangeStatus={handleChangeStatus}
                      accept="image/png,image/jpeg,video/mp4,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      maxFiles={1}
                      canCancel={false}
                      submitButtonContent={null}
                      LayoutComponent={Layout}
                      inputContent={() => <AttachFile size="24" />}
                      inputWithFilesContent={() => <AttachFile size="24" />}
                      styles={{
                        dropzone: {
                          color: "black",
                          border: "none",
                          width: "45px",
                          height: "45px",
                          minHeight: "unset",
                          overflow: "hidden",
                        },
                        inputLabel: {
                          color: "#071435",
                          overflow: "hidden",
                        },
                        inputLabelWithFiles: {
                          color: "#071435",
                          backgroundColor: "transparent",
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          fontSize: "20px",
                          padding: 0,
                          margin: 0,
                          overflow: "hidden",
                        },
                        preview: {
                          position: "fixed",
                          width: "400px",
                          overflow: "scroll",
                          height: "auto",
                          bottom: "15%",
                          right: "0px",
                          zIndex: 1002,
                        },
                      }}
                    />
                  </div>
                </Tools>
                <TextAreaWrapper>
                  <TextArea
                    key="new-question-answer"
                    value={formValues.questionTextAnswer}
                    onChange={handleQuestionInputChange}
                    onBlur={savePreviousText}
                    placeholder={t("type_a_message")}
                    id="question-answer"
                    name="question-answer"
                  />
                </TextAreaWrapper>
              </Container>
            )}
          </QuestionWrapper>
        )}
      </ContainerFromStyles>
      {medicalFormVisible && (
        <MedicalFormContainer>
          <MedicalForm
            handleClose={() => setIsMedicalFormVisible(false)}
            message={medicalForm}
            info={{ clientName: currentQuestion?.clientDisplayName }}
          />
          <Overlay />
        </MedicalFormContainer>
      )}
      {showConfirmationModal && (
        <CustomModal
          modalOpen={showConfirmationModal}
          setModalOpen={setShowConfirmationModal}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                {isDonatedQuestion ? (
                  <p>{t("send_donated_question")}</p>
                ) : (
                  <p>{t("send_question")}</p>
                )}
                <p>{t("send_question_body")}</p>
                <p>{t("send_question_ask")}</p>
                <div>
                  <button
                    type="button"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      color: "black",
                    }}
                    onClick={() => {
                      setShowConfirmationModal(false)
                      setIsDonatedQuestion(false)
                    }}
                  >
                    {t("send_question_cancel")}
                  </button>
                  <SubmitButton
                    isLoading={isButtonLoading}
                    type="button"
                    onClick={submitResponse}
                    medium
                  >
                    {t("send_question_confirm")}
                  </SubmitButton>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      {showChangeQuestionModal && (
        <CustomModal
          modalOpen={showChangeQuestionModal}
          setModalOpen={setShowChangeQuestionModal}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("pending_question_data")}</p>
                <div>
                  <button
                    type="button"
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      color: "black",
                    }}
                    onClick={() => setShowChangeQuestionModal(false)}
                  >
                    {t("cancel")}
                  </button>
                  <SubmitButton
                    isLoading={isButtonLoading}
                    type="button"
                    onClick={() => {
                      onCardClick(newQuestionData, true, false)
                      setShowChangeQuestionModal(false)
                    }}
                    medium
                  >
                    {t("confirm")}
                  </SubmitButton>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
    </SectionsDoubleContainer>
  )
}

Questions.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const CompQuestions = compose(
  withFirebase,
  withAuthorization(condition)
)(Questions)
export default CompQuestions
