import React, { useEffect, useState, useRef } from "react"
import * as Yup from "yup"
import PropTypes from "prop-types"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { useHistory, withRouter, useParams } from "react-router-dom"
import { Formik, Form } from "formik"
import { Visibility } from "@styled-icons/material/Visibility"
import { VisibilityOff } from "@styled-icons/material/VisibilityOff"
import {
  Button,
  VStack,
  Box,
  Image,
  Flex,
  Text,
  InputRightElement,
  InputGroup,
  FormControl,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react"

import { PasswordMessagesContainer, PasswordInfoMessage } from "./styles"

// Components
import { WithChakra } from "../../components/atoms/WithChakra"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"
import CustomInput from "../../components/chakra/CustomInput"

// Utils
import { withFirebase } from "../../utils/Firebase"
import { collaboratorRegisterUrl, honeybadger } from "../../utils/App"

import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"

const EmployeeRegisterBase = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const [showPassword, setShowPassword] = useState(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [emailAlreadyInUse, setEmailIsAlreadyUsed] = useState(false)
  const [organization, setOrganization] = useState()

  const { orgId } = useParams()
  const formRef = useRef()

  const isPhone = (value) =>
    // eslint-disable-next-line no-useless-escape
    /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/.test(
      value
    )

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const toggleShowVerifyPassword = () => {
    setShowVerifyPassword(!showVerifyPassword)
  }

  useEffect(() => {
    if (firebase && orgId) {
      firebase
        .getOrganization(orgId)
        .then((r) => {
          if (r.exists) {
            setOrganization({ orgId: r.id, ...r.data() })
          }
        })
        .catch((e) => {
          window.Honeybadger?.notify(e)
          addToast(t("unexpected_error"), { appearance: "error" })
        })
    }
  }, [firebase, orgId])

  const goToHelp = () => {
    history.push("/help", { isFromLogin: true })
  }

  const createAccount = async (values) => {
    await fetch(collaboratorRegisterUrl, {
      method: "POST",
      body: JSON.stringify({
        userInfo: {
          email: values.email,
          password: values.password,
          displayName: values.name,
          phoneNumber: values.phoneNumber,
          userType: "client",
          country: organization?.country,
          organizationId: organization?.orgId,
        },
        orgName: organization?.businessName,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (data) => {
      try {
        if (data.error || data.status !== 200) {
          const parsedRes = await data.json()

          setIsCreating(false)
          if (
            parsedRes.error.message.includes(
              "The email address is already in use"
            )
          ) {
            addToast(t("error_email_already_in_use"), {
              appearance: "error",
            })
            setEmailIsAlreadyUsed(true)
          } else {
            addToast(t("unexpected_error"), { appearance: "error" })
            setIsCreating(false)
          }
        } else {
          addToast(t("web_client.saved_successfully"), {
            appearance: "success",
            placement: "bottom-center",
          })

          setEmailIsAlreadyUsed(false)
          setIsCreating(false)

          formRef.current.resetForm()

          history.push("/verify", {
            dataVerification: {
              dataToVerify: "EmployeeRegister",
              showPlatformButton: false,
              url: process.env.REACT_APP_ADMIN_APP_SIGNIN,
              title: t("web_client.business_org_register_complete_title"),
              description: t(
                "web_client.employee_org_register_complete_description",
                {
                  businessName: `${organization?.businessName}`,
                }
              ),
            },
          })
        }
      } catch (e) {
        addToast(t("unexpected_error"), {
          appearance: "error",
          placement: "bottom-center",
        })
        setIsCreating(false)
      }
    })
  }

  return (
    <Flex
      direction="column"
      width="full"
      height="100vh"
      overflowX="hidden"
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
      paddingTop="76px"
    >
      <Box
        boxSize="sm"
        position="fixed"
        top={isMobile ? "0" : "12px"}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="50px"
        padding="0px 20px"
        zIndex="1000"
        background={isMobile ? "#FFF" : "transparent"}
      >
        <Box cursor="pointer">
          <Image src={LogoV2} alt="logoV2" onClick={() => history.push("/")} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="200px"
        >
          <HelpButton onClick={() => goToHelp()} />
          <LanguageSelector isFromLogin />
        </Box>
      </Box>
      <VStack
        borderRadius="8px"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text
          fontWeight="400"
          fontSize={isMobile ? "24px" : "36px"}
          fontFamily="Circular Std Medium"
          lineHeight={isMobile ? "40px" : "50px"}
          width="400px"
          textAlign="center"
        >
          {t("web_client.register_welcome_employee", {
            businessName: `${organization?.businessName}`,
          })}
        </Text>
        <Formik
          innerRef={formRef}
          initialValues={{
            name: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(t("error_all_fields_required")),
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            phoneNumber: Yup.string()
              .test(
                "phoneNumber",
                t("web_client.error_invalid_phone_number"),
                (value) => value && isPhone(value)
              )
              .required(t("error_all_fields_required")),
            password: Yup.string().required(t("error_all_fields_required")),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref("password"), null],
              t("web_client.error_password_must_match")
            ),
          })}
          onSubmit={async (values) => {
            try {
              setIsCreating(true)
              await createAccount(values)
            } catch (err) {
              honeybadger.notify("Register - General Information Section", {
                message:
                  "Error on Submit / Register General Information Section",
                action: "submit",
                context: {
                  formValues: values,
                  error: err,
                },
              })
              setIsCreating(false)
              addToast(t("unexpected_error"), { appearance: "error" })
            }
          }}
        >
          {(props) => (
            <Form>
              <Flex
                marginTop="23px"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width={isMobile ? "100%" : "768px"}
              >
                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("web_client.register_fullname")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder={t(
                        "web_client.register_placeholder_fullname"
                      )}
                      width="320px"
                      height="32px"
                      type="text"
                      name="name"
                      id="name"
                      showErrorMessage={props.errors.name && props.touched.name}
                      handleInputOnBlur={() => props.setTouched({ name: true })}
                      errorMessage={props.errors.name}
                      handleInputChange={(e) =>
                        props.setFieldValue("name", e.target.value)
                      }
                      props={{
                        value: props.values.name,
                      }}
                    />
                  </Flex>
                </Box>

                <Box backgroundColor="#FFF" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("email_address")}
                      </Text>
                    </Flex>
                    <Box>
                      <CustomInput
                        placeholder={t("q1_profile_email_placeholder")}
                        width="320px"
                        height="32px"
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        showErrorMessage={
                          (props.errors.email && props.touched.email) ||
                          emailAlreadyInUse
                        }
                        errorMessage={
                          emailAlreadyInUse
                            ? t("error_email_already_in_use")
                            : props.errors.email
                        }
                        handleInputOnBlur={() =>
                          props.setTouched({ email: true })
                        }
                        handleInputChange={(e) => {
                          props.setFieldValue("email", e.target.value)
                          if (emailAlreadyInUse) {
                            setEmailIsAlreadyUsed(false)
                          }
                        }}
                        props={{
                          value: props.values.email,
                        }}
                      />
                    </Box>
                  </Flex>
                </Box>

                <Box backgroundColor="#F7FAFC" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("web_client.phone")}
                      </Text>
                    </Flex>
                    <CustomInput
                      placeholder={t("q7_info_phone_help")}
                      width="320px"
                      height="32px"
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      showErrorMessage={
                        props.errors.phoneNumber && props.touched.phoneNumber
                      }
                      errorMessage={props.errors.phoneNumber}
                      handleInputOnBlur={() =>
                        props.setTouched({ phoneNumber: true })
                      }
                      handleInputChange={(e) =>
                        props.setFieldValue("phoneNumber", e.target.value)
                      }
                      props={{
                        value: props.values.phoneNumber,
                      }}
                    />
                  </Flex>
                </Box>

                <Box backgroundColor="#FFF" width="100%">
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    width={isMobile ? "100%" : "80%"}
                    padding={isMobile ? "10px 20px" : "0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                      height="40px"
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("password")}
                      </Text>
                    </Flex>

                    <Stack>
                      <FormControl>
                        <InputGroup size="md">
                          <CustomInput
                            placeholder={t("title_capture_password")}
                            width={isMobile ? "340px" : "358px"}
                            height="40px"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            autoComplete="off"
                            showErrorMessage={
                              props.errors.password && props.touched.password
                            }
                            errorMessage={props.errors.password}
                            handleInputOnBlur={() =>
                              props.setTouched({ password: true })
                            }
                            handleInputChange={(e) =>
                              props.setFieldValue("password", e.target.value)
                            }
                            props={{
                              value: props.values.password,
                            }}
                            avoidCopyAndPaste
                          />

                          <InputRightElement>
                            <Button size="sm" onClick={toggleShowPassword}>
                              {showPassword ? (
                                <Visibility size="24" />
                              ) : (
                                <VisibilityOff size="24" />
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                      <PasswordMessagesContainer>
                        <PasswordInfoMessage
                          isEmpty={!props.values.password}
                          isValid={props.values.password.match(/[0-9]+/)}
                        >
                          {t("password_contain_number")}
                        </PasswordInfoMessage>
                        <PasswordInfoMessage
                          isEmpty={!props.values.password}
                          isValid={props.values.password.match(/[a-zA-Z]+/)}
                        >
                          {t("password_contain_letters")}
                        </PasswordInfoMessage>
                        <PasswordInfoMessage
                          isEmpty={!props.values.password}
                          isValid={props.values.password.match(
                            // eslint-disable-next-line no-useless-escape
                            /[\!\#\$\^\&\*\-\_\+\=\|\\\/\"\[\]\{\}\(\)]+/
                          )}
                        >
                          {t("password_contain_symbols")}
                        </PasswordInfoMessage>
                        <PasswordInfoMessage
                          isEmpty={!props.values.password}
                          isValid={props.values.password.length >= 6}
                        >
                          {t("password_minimum")}
                        </PasswordInfoMessage>
                      </PasswordMessagesContainer>
                    </Stack>
                  </Flex>
                </Box>

                <Box
                  width="100%"
                  backgroundColor="#F7FAFC"
                  borderBottomRightRadius="10px"
                  borderBottomLeftRadius="10px"
                >
                  <Flex
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    width={isMobile ? "100%" : "80%"}
                    minHeight="84px"
                    padding={isMobile ? "10px 20px" : "10px 0"}
                  >
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      marginLeft={isMobile ? "0" : "24px"}
                    >
                      <Box marginRight="10px" height="22px">
                        *
                      </Box>
                      <Text fontSize="14px" fontWeight="400" color="#1A202C">
                        {t("verify_password")}
                      </Text>
                    </Flex>
                    <Stack>
                      <FormControl>
                        <InputGroup size="md">
                          <CustomInput
                            placeholder={t("confirm_password")}
                            width={isMobile ? "340px" : "358px"}
                            height="40px"
                            type={showVerifyPassword ? "text" : "password"}
                            name="confirmPassword"
                            id="confirmPassword"
                            showErrorMessage={
                              props.errors.confirmPassword &&
                              props.touched.confirmPassword
                            }
                            errorMessage={props.errors.confirmPassword}
                            handleInputOnBlur={() =>
                              props.setTouched({ confirmPassword: true })
                            }
                            handleInputChange={(e) =>
                              props.setFieldValue(
                                "confirmPassword",
                                e.target.value
                              )
                            }
                            props={{
                              value: props.values.confirmPassword,
                            }}
                            avoidCopyAndPaste
                          />

                          <InputRightElement>
                            <Button
                              size="sm"
                              onClick={toggleShowVerifyPassword}
                            >
                              {showVerifyPassword ? (
                                <Visibility size="24" />
                              ) : (
                                <VisibilityOff size="24" />
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </FormControl>
                    </Stack>
                  </Flex>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="220px"
                >
                  <Button
                    colorScheme="blue"
                    background="#576EF5"
                    width="104"
                    height="40px"
                    borderRadius="6px"
                    color="#FFF"
                    fontSize="18px"
                    fontWeight="normal"
                    type="submit"
                    isLoading={isCreating}
                  >
                    {t("save")}
                  </Button>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </VStack>
    </Flex>
  )
}

EmployeeRegisterBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const EmployeeRegister = compose(
  withRouter,
  WithChakra,
  withFirebase
)(EmployeeRegisterBase)
export default EmployeeRegister
